import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Welcome! 👋👋👋`}</h2>
    <p>{`Hi, my name is Drew Ellison. I'm a`}</p>
    <ul>
      <li parentName="ul">{`German Shephard Dad 🐶`}</li>
      <li parentName="ul">{`Loving Husband to a Beautiful Lady 🤵🏻`}</li>
      <li parentName="ul">{`Coffee Enthusiast (Snob) ☕️`}</li>
      <li parentName="ul">{`Cyclist 🚵🏻‍♂️`}</li>
      <li parentName="ul">{`Software Engineer 👨🏻‍💻`}</li>
    </ul>
    <p>{`On this site, I'll be blogging about assorted topics like software design, craft coffee brewing, my software projects and maybe some other random things. `}</p>
    <p>{`Check out my blogs and projects, and if you like what you see, you can show some support by `}<a parentName="p" {...{
        "href": "https://www.buymeacoffee.com/drewellison"
      }}>{`buying me a coffee`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      